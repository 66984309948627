import React from "react"
// import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button'

import ROUTES from "utils/routes";
import { Link } from "gatsby";

const NotFound = () => {
  return (
    <div style={{textAlign: "center"}}>
      <br/><br/><br/><br/>

      <div>The page or file that you are looking for does not exist.</div>

      <br/><br/>

      <Link to={ROUTES.LANDING}><Button>Go Home</Button></Link>
    </div>
  );
}

export default NotFound
